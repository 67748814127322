<template>
  <!-- 预付费配置 -->
  <div class="dataMigration-wrapper">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          label-width="90px"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="配置名称：">
                <el-input
                  v-model="formInline.configName"
                  placeholder="输入配置名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属商户：">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t("button.search") }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="info" icon="el-icon-setting" @click="toConfig()"
                >配置自主停车</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <AuthorityComponent
                ComponentName="el-dropdown"
                align="center"
                @command="handleCommand($event, scope.row)"
                width="80"
              >
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c">删除</el-dropdown-item>
                </el-dropdown-menu>
              </AuthorityComponent>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDt,
  setIndex,
  dateFormat,
  exportExcelNew,
} from "@/common/js/public.js";

export default {
  name: "preChargeSetting",
  data() {
    return {
      formInline: {
        configName: "", // 配置名称
        operationId: "", // 运营商ID
      },
      total: 0,
      page: 0,
      pageSize: 10,
      loading: false,
      tenantList: [],
      tableCols: [
        {
          prop: "configName",
          label: this.$t("list.Configuration_Name"),
          width: "",
        },
        {
          prop: "summaryInfo",
          label: this.$t("list.Configuration_Summary"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "",
        },
        {
          prop: "creatorName",
          label: this.$t("list.creator"),
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "170",
        },
      ],
      tableData: [],
    };
  },
  activated() {
    this.searchData();
  },

  created() {
    this.getTenantList();
  },
  mounted() {
    this.searchData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 配置预付费
    toConfig() {
      this.$router.push({
        path: "parkConfigAE",
        query: {
          type: "add",
        },
      });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    // 操作细则
    handleCommand(command, row) {
      console.log(command == "c", command, row, "command");
      // 查看

      if (command == "a") {
        this.$router.push({
          path: "parkConfigDE",
          query: {
            type: "detail",
            selfParkingId: row.selfParkingId,
          },
        });
        // 编辑
      } else if (command == "b") {
        this.$router.push({
          path: "parkConfigAE",
          query: {
            type: "edit",
            selfParkingId: row.selfParkingId,
          },
        });
        // 删除
      } else if (command == "c") {
        this.$alert("确定要删除吗？", this.$t("pop_up.Tips"), {
          confirmButtonText: this.$t("pop_up.Determine"),
        })
          .then(() => {
            this.$axios
              .post("/acb/2.0/selfParking/delete", {
                data: {
                  selfParkingId: row.selfParkingId,
                },
              })
              .then((res) => {
                if (res.state === 0) {
                  this.$message({
                    message: "删除成功！",
                    type: "success",
                  });
                  this.searchData();
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    // 搜索
    searchData() {
      this.loading = false;
      this.tableData = [];
      this.formInline.page = this.page;
      this.formInline.pageSize = this.pageSize;
      this.$axios
        // .get("/acb/2.0/preChargeSetting/query", {
        .get("/acb/2.0/selfParking/list", {
          data: this.formInline,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = setIndex(this.page, res.value.list);
          this.total = res.value.total * 1 || 0;
        });
    },
  },
};
</script>
<style lang="stylus">
.dataMigration-wrapper {
  .el-checkbox:last-of-type {
    margin-left: 2px;
  }
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  // background: #FFFFFF;
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // background: #EFF2F7;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}
</style>
